import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel"];

  connect() {
    // Show panel when frame loads
    this.element.addEventListener("turbo:frame-load", () => {
      requestAnimationFrame(() => {
        this.panelTarget.classList.add("show");
        document.body.style.overflow = "hidden"; // Disable main page scroll
      });
    });
  }

  hide(event) {
    event.preventDefault();
    this.panelTarget.classList.remove("show");
    document.body.style.overflow = "auto"; // Re-enable main page scroll

    this.panelTarget.addEventListener(
      "transitionend",
      () => {
        this.element.src = "";
      },
      { once: true },
    );
  }
}
