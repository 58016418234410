import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "newTagList",
    "tagList",
    "tagButton",
    "newTagInput",
    "errorsList",
  ];

  connect() {
    var multiSelectElements =
      this.element.querySelectorAll(".multiple_dropdown");
    multiSelectElements.forEach((multiselect_element) => {
      this.allowMultiSelect(multiselect_element);
    });
  }

  showNewTag() {
    this.tagButtonTarget.classList.toggle("d-none");
    this.newTagListTarget.classList.toggle("d-none");
  }

  showNewField(event) {
    const field_name = event.target.closest(
      '[data-action="click->tag#showNewField"]',
    ).dataset.fieldName;

    this.element
      .querySelector(`#${field_name}_button`)
      .classList.toggle("d-none");
    this.element
      .querySelector(`#${field_name}_container`)
      .classList.toggle("d-none");
  }

  async addNewTag() {
    const new_tag = this.newTagInputTarget.value;
    try {
      const response = await fetch(`/new/${new_tag}/${"Product"}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        const selectList = this.tagListTarget;

        // Create a new option element for the newly created tag
        this.createOption(selectList, data.new_tag, data.new_tag);

        this.showNewTag();
        this.newTagInputTarget.value = "";
        this.errorsListTarget.classList.add("d-none");
      } else {
        if (data.errors) {
          this.showErrors(data.errors, this.errorsListTarget);
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  showErrors(errors, error_container) {
    error_container.textContent = "";

    const errorElements = Object.entries(errors)
      .map(([attribute, attributeErrors]) =>
        attributeErrors.map((errorMessage) => `${attribute} ${errorMessage}`),
      )
      .flat()
      .map((errorMessage) => {
        const errorMessageElement = document.createElement("div");
        errorMessageElement.textContent = errorMessage;
        return errorMessageElement;
      });

    error_container.append(...errorElements);
    error_container.classList.remove("d-none");
  }

  allowMultiSelect(multiselect_element) {
    multiselect_element.addEventListener("mousedown", function (e) {
      e.preventDefault();

      var scroll = multiselect_element.scrollTop;

      e.target.selected = !e.target.selected;

      setTimeout(function () {
        multiselect_element.scrollTop = scroll;
      }, 0);

      multiselect_element.focus();
    });

    multiselect_element.addEventListener("mousemove", function (e) {
      e.preventDefault();
    });
  }

  async addNewField(event) {
    const field_name = event.target.closest(
      '[data-action="click->tag#addNewField"]',
    ).dataset.fieldName;
    const field_value = this.element.querySelector(`#new_${field_name}_input`);
    const hidden_field = this.element.querySelector(
      `#${field_name}_hidden_field_id`,
    );
    const field = this.element.querySelector(`#${field_name}_field`);
    const error_container = this.element.querySelector(`#${field_name}_errors`);

    try {
      const response = await fetch(
        `/products/new_${field_name}?field_value=${field_value.value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();

      if (response.ok) {
        if (field_name === "role") {
          // If field is "role", add as a radio button instead of an option
          this.createRadioButton(data.new_field_id, data.new_field_value);
        } else if (field_name === "vendor") {
          // Handle vendor dropdown case
          this.createOption(field, data.new_field_id, data.new_field_value);
        } else {
          // Handle other cases
          field.value = data.new_field_value;
          hidden_field.value = data.new_field_id;
        }

        // Hide new input container and show new button
        field_value.value = "";
        this.element
          .querySelector(`#${field_name}_button`)
          .classList.toggle("d-none");
        this.element
          .querySelector(`#${field_name}_container`)
          .classList.toggle("d-none");
        error_container.classList.add("d-none");
      } else {
        if (data.errors) {
          this.showErrors(data.errors, error_container);
        } else {
          console.error("Request failed:", response);
        }
      }

      // Hide role error after adding new role
      const role_error_container = document.querySelector("#role_error");
      if (role_error_container) {
        role_error_container.classList.add("d-none");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // Function to create and append a new radio button
  createRadioButton(value, textContent) {
    const roleList = document.querySelector("ul.role-list"); // Adjust selector if needed
    const index = document.querySelectorAll(".role-list input").length; // Get next index

    // Create list item
    const listItem = document.createElement("li");
    listItem.className = "my-1 float-start";

    // Create radio button
    const radioButton = document.createElement("input");

    radioButton.type = "radio";
    radioButton.name = "place_role[role_id]"; // Ensure only one is selected
    radioButton.value = value;
    radioButton.className = "btn-check";
    radioButton.id = `role_${index}`;
    radioButton.checked = true; // Automatically select new role

    // Create label
    const label = document.createElement("label");
    label.htmlFor = `role_${index}`;
    label.className = "btn btn-outline-alternate px-2 fs-xs text-nowrap me-2";
    label.style.paddingTop = "3px";
    label.style.paddingBottom = "2px";
    label.textContent = textContent;

    // Append elements
    listItem.appendChild(radioButton);
    listItem.appendChild(label);
    roleList.prepend(listItem);
  }

  // Function to create and append a new option (for dropdowns)
  createOption(select_list, value, text_content) {
    const newOption = document.createElement("option");
    newOption.value = value;
    newOption.textContent = text_content;
    select_list.prepend(newOption);
    newOption.selected = true; // Set new option as selected
  }
}
