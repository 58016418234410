import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "address1", "address2", "state", "city", "zip"];

  copyToFields(event) {
    event.preventDefault();
    const sourceElement = event.currentTarget.closest(".its-copy-btn");
    const addressText = sourceElement.dataset.copyAddressValue;
    // Parse the address string
    // Example format: "863 Halekauwila Street, Honolulu, Hawaii, 96813"

    // format can vary cause 'address2' and 'state' are not mandatory fields;
    // so made changes to make sure that 'parts' array is always of 5 length to assign correct values
    const parts = addressText.split(",").map((part) => part.trim());
    const [address1, address2, city, state, zip] = parts;

    // Fill in the fields
    this.address1Target.value = address1;
    this.address2Target.value = address2;
    this.cityTarget.value = city;

    // Find and select the correct state in the dropdown
    const stateOption = Array.from(this.stateTarget.options).find(
      (option) => option.text.trim() === state,
    );
    if (stateOption) {
      this.stateTarget.value = stateOption.value;
    } else {
      this.stateTarget.value = null;
    }

    this.zipTarget.value = zip;

    // Show feedback
    const button = event.currentTarget;
    const originalText = button.textContent.trim();
    button.textContent = "Copied!";
    setTimeout(() => {
      button.textContent = originalText;
    }, 1000);
  }
}
