import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainImage"];

  connect() {
    this.scale = 1; // Default scale
    this.currentThumbnail = this.element.querySelector(".thumbnail.selected"); // Set first selected thumbnail
  }

  zoomImage(event) {
    const rect = this.mainImageTarget.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    const zoomFactor = 2.5; // Stronger zoom

    const percentX = (offsetX / rect.width) * 100;
    const percentY = (offsetY / rect.height) * 100;

    this.mainImageTarget.style.transform = `scale(${zoomFactor})`;
    this.mainImageTarget.style.transformOrigin = `${percentX}% ${percentY}%`;
  }

  resetZoom() {
    this.mainImageTarget.style.transform = "scale(1)";
  }

  changeImage(event) {
    // Get the new image URL from the clicked thumbnail
    const newImageUrl = event.currentTarget.dataset.galleryImageUrl;

    // Update the main image's source
    this.mainImageTarget.src = newImageUrl;

    // Update the download link URL with 'disposition: attachment'
    const downloadLink = document.querySelector(".image_download_btn");
    if (downloadLink) {
      downloadLink.href = `${newImageUrl}?disposition=attachment`;
    }

    document.querySelectorAll(".thumbnail img").forEach((img) => {
      img.classList.remove("selected-img");
    });

    const newImage = event.currentTarget.querySelector("img");
    if (newImage) newImage.classList.add("selected-img");

    this.currentThumbnail = event.currentTarget;
  }
}
